import Lottie from 'lottie-react';
import { animationData } from 'lib/constants/animation';
import styled from 'styled-components';

// We can switch to the hook like what is used in the QueryHandler once we migrate
// - SubscriptionGuard
// - Page
export const Loader = styled(Lottie).attrs({
  animationData: animationData,
  autoplay: true,
  loop: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
})`
  height: 400px;
  width: 400px;
  margin: 0 auto;
`;
